import styled from "styled-components";

export const MainDiv = styled.div`
  margin: 1% 8% 0% 8%;
  padding: 0;
  display: grid;
  
  @media (max-width: 768px) {
    margin: 10% 5% 0% 5%;
  }
  @media (max-width: 600px) {
    margin: 18% 5% 0% 5%;
  }
  @media (max-width: 393px) {
    margin-top: 22%;
  }
  @media (max-width: 390px) {
    margin-top: 20%;
  }
  @media (max-width: 320px) {
    margin-top: 23%;
  }
`;

export const GridRowLeft = styled.div`
  display: grid;
  grid-template-columns: 53% 50%;
  @media (max-width: 768px) {
    display: block;
    margin-top: 0%;
  }
`;

export const GridRowRight = styled.div`
  display: grid;
  grid-template-columns: 50% 53%;
  @media (max-width: 768px) {
    //display: block;
    margin-top: 14%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const TextContainer = styled.div`
  padding: 50px;
  @media (max-width: 768px) {
    display: flex;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  padding: 50px;
  display: grid;
  justify-items: center;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const IconContainer = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  width: 90%;
`;

export const IconSubContainer = styled.div`
  display: block;
  text-align: center;
`;

export const Icon = styled.img`
  display: inline;
  width: 70%;
  @media (max-width: 768px) {
    width: 140px;
  }
`;

export const Image = styled.img`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  margin-top: 50px;
  font-size: 24px;
  color:var(--font-color);
  opacity: 0.8;
  @media (max-width: 1440px) {
    font-size: 16px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 10px;
  }
`;

export const Header5 = styled.h5`
  @media (max-width: 768px) {
    color: #007dc3;
    font-size: 18px;
  }
`;

export const Header1 = styled.h1`
  font-size: 46px;
  font-weight: bold;
  color:var(--font-color);
  @media (max-width: 1440px) {
    font-size: 34px;
  }
  @media (max-width: 1024px) {
    font-size: 28px;
  }
  @media (max-width: 768px) {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }
  @media (max-width: 425px) {
    font-size: 26px;
  }
`;

export const PDFLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-size: 24px;
  border: 1px solid;
  padding: 5px;
  opacity: 0.8;
  background: #00ae4f;
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  &:hover {
    color: #fff;
  }
  @media (max-width: 1440px) {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
